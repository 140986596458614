<template>
    <modal ref="modalAsignarContrasena" titulo="Asignar Contraseña" no-aceptar adicional="Enviar" @adicional="enviar">
        <ValidationObserver ref="form" class="row mx-0 justify-center my-3">
            <div class="col-10 text-general f-15 text-center">
                Crea una contraseña para iniciar sesión con el correo electrónico o número de telefono registrado.
            </div>
            <!-- <div v-if="showLastPassword" class="col-10">
                <ValidationProvider v-slot="{ errors }" rules="required" vid="last_password" name="contraseña">
                    <p class="text-general p-2">
                        Contraseña anterior
                    </p>
                    <el-input
                    v-model="model.last_password"
                    class="w-100"
                    show-password
                    />
                    <span class="text-danger f-11" v-text="errors[0]" />
                </ValidationProvider>
            </div> -->
            <div class="col-10">
                <p class="text-general p-2">
                    Contraseña
                </p>
                <ValidationProvider v-slot="{ errors }" :rules="reglas.contrasena" vid="password" name="contraseña">
                    <el-input
                    v-model="model.password"
                    class="w-100"
                    maxlength="200"
                    minlength="8"
                    show-password
                    />
                    <span class="text-danger f-11" v-text="errors[0]" />
                    <p class="text-muted f-12 mt-2">La contraseña debe tener mínimo 8 cacacteres y contener al menos 1 letra</p>
                </ValidationProvider>
            </div>
            <div class="col-10">
                <p class="text-general p-2">
                    Confirmar Contraseña
                </p>
                <ValidationProvider v-slot="{ errors }" rules="required_if:password|confirmed:password" name="confirmar contraseña">
                    <el-input
                    v-model="model.password_confirmation"
                    class="w-100"
                    show-password
                    />
                    <span class="text-danger f-11" v-text="errors[0]" />
                </ValidationProvider>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import datosAcceso from "~/services/configurar/tendero-vip/datos_acceso";
export default {
    props: {
        showLastPassword: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            model: {
                password: '',
                password_confirmation: '',
                last_password: '',
            },
            reglas: {
                contrasena: {
                    required: true,
                    min: 8,
                    max: 200,
                    regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*.?\-_\/&:,;{}+*'¿¡!]?)([A-Za-z\d@$!.%*?\-_\/&:,;{}+*'¿¡!]){8,200}$/
                }
            },
        }
    },
    methods: {
        toggle(){
			this.model.password = ''
			this.model.password_confirmation = ''
			this.model.last_password = ''
            this.$refs.modalAsignarContrasena.toggle()
        },
        async enviar(){
            try {
                const valid = await this.$refs.form.validate()
                if(!valid) return
                const { data } = await datosAcceso.vincularPassword(this.model)
                if(data.success){
                    this.notificacion('', 'Se ha vinculado correctamente', 'success')
                    this.$emit('update')
                    this.toggle()
                }
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
